<template>
  <div>
    <h2>システム管理者</h2>
    <pv-data-table ref="dt" :value="admins" :rows="10" :totalRecords="totalRecords"
      :loading="loading" paginator lazy @page="loadAdmins" @sort="loadAdmins">
      <template #header>
        <div class="flex justify-content-between">
          <div class="flex justify-content-start">
          </div>
          <div class="flex justify-content-end">
            <pv-button label="登録" icon="pi pi-plus" @click="adminDialog = true" />
          </div>
        </div>
      </template>
      <pv-column field="id" header="ID" sortable />
      <pv-column field="email" header="メールアドレス" sortable />
      <pv-column field="created_at" header="登録日時" sortable>
        <template #body="{data}">
          {{ dayjs(data.created).format('YYYY/MM/DD HH:mm') }}
        </template>
      </pv-column>
      <pv-column field="id">
        <template #body="{data}">
            <pv-button class="ml-2 p-button-text" icon="pi pi-pencil" @click="editAdmin(data)" />
            <pv-button class="ml-2 p-button-danger p-button-text" icon="pi pi-trash" @click="removeAdmin(data)" />
        </template>
      </pv-column>
    </pv-data-table>
  </div>

  <pv-dialog v-model:visible="adminDialog" :header="admin.id ? 'アカウント編集' : 'アカウント登録'" modal :closable="false" :style="{width: '420px'}">
    <form @submit.prevent="updateAdmin">
      <div class="formgrid grid">
        <label class="col-4 input-label">メールアドレス</label>
        <div class="col-8 field">
          <div class="p-input-icon-right w-full">
            <i class="pi pi-envelope" />
            <input-text type="email" v-model="admin.email" :error="error.email" class="w-full" required />
          </div>
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4 input-label">パスワード</label>
        <div class="col-8 field">
          <pv-password v-model="admin.password" :error="error.password" full :required="false" />
        </div>
      </div>

      <input v-show="false" ref="formSubmit" type="submit" />
    </form>

    <template #footer>
      <pv-button label="取消" class="p-button-text" @click="cancel" />
      <pv-button :label="admin.id ? '更新' : '登録'" @click="formSubmit.click()" />
    </template>
  </pv-dialog>

  <pv-dialog header="アカウント削除" v-model:visible="confirmDelete" modal :closable="false">
    <div class="confirmation-content">
      <span><strong>{{ delAdmin.email }}</strong>を削除します。<br />よろしいですか？</span>
    </div>
    <template #footer>
      <pv-button label="取消" class="p-button-text" @click="confirmDelete = false" />
      <pv-button label="削除" @click="removeAdmin(delAdmin, true)" />
    </template>
  </pv-dialog>
</template>

<style scoped>
::v-deep(.p-dropdown) {
  max-height: 2.5em;
}
::v-deep(.field > .p-float-label > label) {
  font-weight: normal!important;
}
::v-deep(.p-column-header-content) {
  position: relative;
}
::v-deep(th:last-of-type > .p-column-header-content) {
  float: right;
}
::v-deep(.p-column-header-content > .p-sortable-column-icon) {
  position: absolute;
  left: -27px;
}

.flex.justify-content-end {
  max-height: 2.5em;
}
</style>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { getAdmins, postAdmin, deleteAdmin } from "@/common/api";

export default {
  layout: "app",
  setup() {
    const store = useStore();
    const dt = ref();
    const loading = ref(false);
    const totalRecords = ref(0);
    const admins = ref([]);
    const adminParams = ref({});

    onMounted(async () => {
      await loadAdmins({
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
      });
    });

    async function loadAdmins(params) {
      if (!store.getters.isLogin) return;
      loading.value = true;
      adminParams.value = params;
      let data = await getAdmins(params);

      admins.value = data.admins;
      totalRecords.value = data.total;
      loading.value = false;
    }

    const adminDialog = ref(false);
    const admin = ref({});
    const error = ref({ email: "", password: "" });
    const formSubmit = ref();

    watch(() => adminDialog.value, (dialog) => {
      if (!dialog) {
        admin.value = {};
      }
    });

    const toast = useToast();

    async function updateAdmin() {
      let data = await postAdmin(admin.value);
      if (data.error) {
        if (data.error == "email already exists") {
          error.value.email = "既に登録済みのメールアドレスです";
        }
        return;
      }

      if (admin.value.id) {
        toast.add({ severity: "success", summary: "アカウント更新", detail: "アカウントを更新しました", life: 3000 });
      } else {
        toast.add({ severity: "success", summary: "アカウント登録", detail: "アカウントを登録しました", life: 3000 });
      }

      adminDialog.value = false;

      await loadAdmins(adminParams.value);
    }

    function editAdmin(a) {
      admin.value = {...a};
      adminDialog.value = true;
    }

    function cancel() {
      admin.value = {};
      error.value = {};
      adminDialog.value = false;
    }

    const confirmDelete = ref(false);
    const delAdmin = ref({});

    async function removeAdmin(a, force=false) {
      if (!force) {
        delAdmin.value = a;
        confirmDelete.value = true;
        return;
      }

      await deleteAdmin(a.id);
      confirmDelete.value = false;
      await loadAdmins(adminParams.value);
    }

    return {
      dt, loading, totalRecords, admins,
      adminDialog, admin, formSubmit, error, cancel,
      confirmDelete, delAdmin, removeAdmin,
      loadAdmins, updateAdmin, editAdmin,
    };
  }
}
</script>
